<template>
    <div class="table-pagination">
        <div class="table-pagination__page-size-selector">
            <label>{{ $t('table.showing') }}</label>
            <pui-form-select
                :options="paginationOptions"
                :value="selectedPageSize.toString()"
                label=""
                search-input-placeholder=" "
                @change="onPageSizeChange"
            />
            <label>{{ $t('table.recordCount', { totalCount }) }}</label>
        </div>
        <div class="table-pagination__page-selector">
            <span
                v-if="selectedPageNumber !== 1"
                class="table-pagination__page-selector__first-page"
                @click="goToFirstPage"
            >
                {{ $t('table.first') }}
            </span>
            <pui-pagination
                v-model="selectedPageNumber"
                :range-of-pages="9"
                :total-pages="totalPages"
                @changed:page="pageNumberChanged"
            />
            <span
                v-if="selectedPageNumber !== totalPages"
                class="table-pagination__page-selector__last-page"
                @click="goToLastPage"
            >
                {{ $t('table.last') }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import { SelectOption } from '@/models/interfaces';

export const TablePaginationDefaults = {
    INITIAL_PAGE_SIZE: 10,
    INITIAL_PAGE_NUMBER: 1,
    PAGE_SIZE_SELECTION_OPTIONS: [
        10, 25, 50, 100
    ]
};

@Component({})
export default class TablePaginationComponent extends Vue {
    @Prop({ required: true, default: 0 })
    totalCount!: number;

    selectedPageSize = TablePaginationDefaults.INITIAL_PAGE_SIZE;
    selectedPageNumber = TablePaginationDefaults.INITIAL_PAGE_NUMBER;

    private get paginationOptions(): SelectOption[] {
        const availablePageSizes = TablePaginationDefaults.PAGE_SIZE_SELECTION_OPTIONS
            .filter(option => option < this.totalCount);

        if (!availablePageSizes.length) {
            this.selectedPageSize = this.totalCount;

            return [{
                label: this.totalCount.toString(),
                value: this.totalCount.toString()
            }];
        }

        if (this.selectedPageSize < TablePaginationDefaults.INITIAL_PAGE_SIZE) {
            this.selectedPageSize = TablePaginationDefaults.INITIAL_PAGE_SIZE;
        }

        return availablePageSizes.map(option => ({
            label: option.toString(),
            value: option.toString()
        }));
    }

    private get totalPages(): number {
        return this.totalCount === 0 ? 0 : Math.ceil(this.totalCount / this.selectedPageSize);
    }

    created(): void {
        this.pageNumberChanged();
        this.pageSizeChanged();
    }

    @Emit('change:page-number')
    pageNumberChanged(): number {
        return this.selectedPageNumber;
    }

    @Emit('change:page-size')
    pageSizeChanged(): number {
        return this.selectedPageSize;
    }

    public goToFirstPage(): void {
        this.selectedPageNumber = 1;
        this.pageNumberChanged();
    }

    private goToLastPage(): void {
        this.selectedPageNumber = this.totalPages;
        this.pageNumberChanged();
    }

    private onPageSizeChange(newSize: string): void {
        this.selectedPageSize = parseInt(newSize);
        this.goToFirstPage();
        this.pageSizeChanged();
    }
}
</script>

<style lang="less">
.table-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & div:last-child {
        display: flex;
        align-items: center;
    }

    &__page-size-selector {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            margin-bottom: 0;

            &:first-child {
                margin-right: 0.5rem;
            }

            &:last-child {
                margin-left: 0.5rem;
            }
        }

        input {
            min-width: 75px;
        }
    }

    &__page-selector {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__first-page {
            padding-right: 2rem;
            padding-bottom: 0.1rem;
            cursor: pointer;
        }

        &__last-page {
            margin-right: 2rem;
            padding-left: 2rem;
            padding-bottom: 0.1rem;
            cursor: pointer;
        }
    }

    &--firstpage, &--lastpage {
        padding-bottom: 0.1rem;
        cursor: pointer;
    }
}

</style>
