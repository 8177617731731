<template>
    <pui-form-type-ahead
        v-model="userInput"
        :is-loading="isLoading"
        :is-valid="isValid"
        :options="searchResults"
        :use-local-cache="false"
        :label="$t('commentLightbox.fields.people.label')"
        :multiple="true"
        @abort="clearSearchResults"
        @change="userChanged"
        @search="fetchUsers"
    >
        <template #default="data">
            <pui-avatar
                :username="data.option.label"
                :initials="avatarInitials(data.option.name)"
                size="24px"
                small-username
                noedit
                noshadow
            />
        </template>
    </pui-form-type-ahead>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { SelectOption } from '@/models/interfaces';
import { Prop, Watch } from 'vue-property-decorator';

const MAX_NUMBER_OF_RESULTS = 5 as const;

@Component({})
export default class UamUserInputComponent extends Vue {
    @Prop({ default: () => [] })
    private initialValue!: SelectOption[];

    @Prop({ default: () => true })
    private isValid!: boolean;

    private abortController = new AbortController();

    private userInput: SelectOption[] = [];
    private searchResults: SelectOption[] = [];
    private isLoading = false;

    @Watch('initialValue', { immediate: true })
    onInitialValueChanged(value: SelectOption[]): void {
        this.userInput = value;
    }

    private async fetchUsers(searchQuery: string): Promise<void> {
        this.isLoading = true;
        this.clearSearchResults();

        try {
            const users = await this.$store.dispatch('comments/searchUsers', { searchQuery, signal: this.abortController.signal });
            this.searchResults = users
                .slice(0, MAX_NUMBER_OF_RESULTS)
                .map((user: { name: string; kid: string }) => ({
                    label: `${user.name} (${user.kid})`,
                    value: user.kid,
                    name: user.name,
                }));
        } finally {
            this.isLoading = false;
        }
    }

    private userChanged(): void {
        this.$emit('update:users', this.userInput);
    }

    private clearSearchResults(): void {
        this.searchResults = [];
    }

    private avatarInitials(username: string): string {
        let letters = '';
        if (username) {
            letters = username
                .split(' ')
                .map((x) => x[0])
                .join('');
            if (letters.length > 2) {
                return letters.substring(0, 2) + letters.substring(letters.length - 1);
            }
        }
        return letters;
    }
}
</script>
