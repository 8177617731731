<template>
    <div
        v-if="statusColor"
        class="legend-item"
    >
        <span
            :style="{
                'background-color': statusColor,
            }"
            :class="[{ 'legend-item__circle--with-text': hasStatusLabel }, { 'legend-item__circle--clickable': clickable },, 'legend-item__circle']"
            @click="legendClicked"
        >
            <span class="legend-item__circle__text">
                {{ statusLabel }}
            </span>
        </span>
        <span
            v-if="hasLabel"
            :style="{
                'color': labelColor,
                'font-size': labelFontSize
            }"
            :class="[{ 'legend-item__label--with-status-label': hasStatusLabel }, 'legend-item__label']"
        >
            {{ label }}
        </span>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({})
export default class LegendItemComponent extends Vue {
    /**
     * This prop is required to know the color for the circle.
     * Valid values are valid values for background-color css property.
     */
    @Prop()
    private statusColor!: string;

    /**
     * This prop is used to display a small letter on the circle.
     */
    @Prop({ default: '' })
    private statusLabel?: string;

    /**
     * This prop is used to know the color for the label.
     * Valid values are valid values for color css property.
     */
    @Prop({ default: 'transparent' })
    private labelColor?: string;

    /**
     * This prop is used to know the font-size for the label.
     * Valid values are valid values for font-size css property.
     */
    @Prop({ default: '1rem' })
    private labelFontSize?: string;

    /**
     * This prop is used to display the legend label.
     */
    @Prop({ default: '' })
    private label?: string;

    /**
     * This prop is used to know if legend is clickable.
     */
    @Prop({ default: false })
    private clickable?: boolean;

    private get hasStatusLabel(): boolean {
        return this.statusLabel !== '';
    }

    private get hasLabel(): boolean {
        return this.label !== '';
    }

    private legendClicked(): void {
        this.$emit('legendClicked')
    }
}
</script>

<style lang="less" scoped>
@import "../../../variables.less";

.legend-item {
    display: flex;
    align-items: center;

    &__circle {
        border-radius: 6px;
        width: 1.2rem;
        height: 1.2rem;
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;

        &--with-text {
            border-radius: 1.75rem;
            width: 3rem;
            height: 3rem;
        }

        &--clickable {
            cursor: pointer;
        }

        &__text {
            font-size: 1.2rem;
            font-weight: 500;
            color: @white;
            margin: 0 auto;
        }
    }

    &__label {
        line-height: 1.8rem;
        margin-left: 1.1rem;

        &--with-status-label {
            margin-left: 0.4rem;
        }
    }
}
</style>
