<template>
    <div class="monitor-details">
        <pui-breadcrumb
            :links="links"
            @changed:active-route="onActiveRouteChanged"
        />
        <div class="monitor-details__timeframe-wrapper">
            <div class="monitor-details__timeframe-wrapper__title">
                <pui-headline type="h6">
                    {{ isCurrentOverviewValueType ? $t('latestImportedValues') : $t('selectedTimeframe') }}
                </pui-headline>
            </div>
            <div
                v-if="!isCurrentOverviewValueType"
                class="monitor-details__timeframe-wrapper__content"
            >
                {{ selectedTimeframe }}
            </div>
        </div>
        <div class="monitor-details__table-wrapper">
            <div class="monitor-details__filter-row">
                <pui-tooltip />
                <pui-filter
                    v-if="isValidationProcess"
                    :use-router="false"
                    :config="getFilter()"
                    vuex-namespace="qmdi-monitor-details"
                    @changed:applied-values="filterChanged"
                />
                <pui-button
                    v-if="isAllowedToEditContent"
                    :disabled="this.isMarkAsReadButtonDisabled"
                    class="monitor-details__filter-row__mark-as-read"
                    @click="markAllAsRead"
                >
                    {{ $t('markAsRead') }}
                </pui-button>
            </div>
            <div class="monitor-details__table">
                <pui-table
                    :ref="DETAILS_TABLE_REF"
                    :vendor-options="vendorOptions"
                    with-server
                    with-pagination
                    with-limit
                    with-search
                >
                    <template
                        slot="message"
                        slot-scope="data"
                    >
                        <span class="monitor-details__message">
                            <template v-if="data.row.data.message">
                                <tooltip :message="data.row.data.message">
                                    {{ data.row.data.message }}
                                </tooltip>
                            </template>
                            <template v-else>
                                {{ $t('notApplicableShort') }}
                            </template>
                        </span>
                    </template>
                    <template
                        slot="status"
                        slot-scope="data"
                    >
                        <legend-item
                            :status-color="getLegendDetails(data.row.status).color"
                            :label-color="PUICOLOR_DARK_GREY"
                            :label="getLegendDetails(data.row.status).label"
                            label-font-size="1.4rem"
                        />
                    </template>
                    <template
                        slot="readStatus"
                        slot-scope="data"
                    >
                        <div
                            v-if="data.row.data.markAsRead"
                            class="monitor-details__read-status-cell"
                        >
                            <pui-icon
                                :icon-color="PUICOLOR_CONTEXTUAL_GREEN"
                                icon-name="check"
                            />
                            {{ $t('read') }}
                        </div>
                        <div
                            v-else
                            class="monitor-details__read-status-cell"
                        >
                            <pui-icon
                                :icon-color="PUICOLOR_DARK_GREY"
                                icon-name="close"
                            />
                            {{ $t('unread') }}
                        </div>
                    </template>
                    <template
                        slot="versionTime"
                        slot-scope="data"
                    >
                        {{ getFormattedVersionTime(data.row.versionTime) }}
                    </template>
                    <template
                        slot="comments"
                        slot-scope="data"
                    >
                        <div
                            class="monitor-details__comment-cell"
                            @click="openCommentModal(data.row)"
                        >
                            <pui-link
                                v-if="isAllowedToEditContent"
                                :title="$t('addComments')"
                                icon="icon-add"
                            />
                            <pui-link
                                v-else
                                :title="$t('viewComments')"
                                :class="{
                                    'monitor-details__comment-link--disabled': !data.row.comment,
                                }"
                                icon="icon-visible"
                            />
                            <div
                                v-if="data.row.comment"
                                class="monitor-details__comment-indicator"
                            >
                                1
                            </div>
                        </div>
                    </template>
                </pui-table>
            </div>
        </div>
        <comments-lightbox
            :comment-details="selectedCommentRow"
            :lightbox-open="isCommentLightboxOpen"
            @close-lightbox="(refresh) => handleCloseCommentLightbox(refresh)"
        />
    </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    PuiBreadcrumbLink,
    PuiFilterAppliedValues,
    PuiFilterConfig,
    PuiNavigationToolbarClickEvent,
    PuiTableRequestFunctionParams,
    PuiTableVendorOptions,
    PuiTableVendorOptionsSelectable,
    PuiTableWithServerResponse,
} from '@/models/pebble-ui';
import {
    CachedUser,
    LegendDetails,
    MarkAsReadPayload,
    MonitorDetails,
    MonitorDetailsDataParams,
    MonitorDetailsResponse,
    OverviewValueType,
    ProcessDetailsTableValue
} from '@/models/interfaces';
import { MONITOR_PATH } from '@/config/router-config';
import { CANCEL_MESSAGE } from '@/config/consts';
import { AMBER_STATUS, GREY_STATUS, PROCESS_STATUS, RED_STATUS } from '@/config/process-statuses';
import { PROCESSES, SUB_PROCESSES, VALIDATION } from '@/config/processes';
import { PUICOLOR_CONTEXTUAL_GREEN, PUICOLOR_DARK_GREY } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { CancelTokenSource } from 'axios';
import LegendItem from '@/components/ui/legend-item/legend-item.vue';
import Tooltip from '@/components/ui/tooltip.vue';
import CommentsLightbox from './comments/comments-lightbox.vue';
import { AccessControlled } from '@/mixins/access-controlled';
import { Permission } from '@/config/permissions';

@Component({
    components: {
        LegendItem,
        Tooltip,
        CommentsLightbox,
    }
})
export default class MonitorDetailsComponent extends mixins(AccessControlled) {
    private abortController = new AbortController();
    private readonly axios = require('axios');
    private readonly COLUMNS = ['source', 'process', 'status', 'readStatus', 'markAsReadBy', 'versionTime', 'message', 'comments'];
    private readonly PUICOLOR_DARK_GREY = PUICOLOR_DARK_GREY;
    private readonly PUICOLOR_CONTEXTUAL_GREEN = PUICOLOR_CONTEXTUAL_GREEN;
    private readonly DETAILS_TABLE_REF = 'details-table-ref';
    private readonly ASC = 1;
    private readonly DESC = 2;
    private readonly SORT: Record<number, number> = {
        0: this.DESC,
        1: this.ASC
    }
    private source = {} as CancelTokenSource;
    private isMarkAsReadLoading = false;
    private selectedRows: MonitorDetails[] = [];
    private headings: Record<string, string> = {};
    private appliedStatusFilter: number[] = [];
    private isCommentLightboxOpen = false;
    private selectedCommentRow = {} as MonitorDetails;

    /**
     * This prop is required to know the process.
     */
    @Prop()
    private process!: number;
    /**
     * This prop is required to know the sub proces.
     */
    @Prop()
    private subProces!: number;
    /**
     * This prop is required to know the overview value type.
     */
    @Prop()
    private overviewValueType!: OverviewValueType;
    /**
     * This prop is required to know the start date of the query.
     */
    @Prop()
    private fromDate!: string;
    /**
     * This prop is required to know the end date of the query.
     */
    @Prop()
    private toDate!: string;

    private get links(): PuiBreadcrumbLink[] {
        const processName = PROCESSES[this.process].label;
        const subProcesName = SUB_PROCESSES[this.subProces].label;
        return [
            {
                label: this.$t('monitor'),
                href: MONITOR_PATH
            },
            {
                label: `${this.$t(processName)}/${this.$t(subProcesName)}`,
                href: ''
            }
        ]
    }

    private get currentUserKid(): string {
        return this.$store.getters['user/getUserInformation']?.kid ?? '';
    }

    private get isAllowedToEditContent(): boolean {
        return this.hasSomePermission(Permission.EDITOR, Permission.ADMIN);
    }

    private get isCurrentOverviewValueType(): boolean {
        return Number(this.overviewValueType) === OverviewValueType.CURRENT;
    }

    private get tableSelectableConfiguration(): PuiTableVendorOptionsSelectable | undefined {
        if (!this.isAllowedToEditContent) {
            return undefined;
        }

        return {
            mode: 'multiple',
            only: function (): boolean {
                return true
            },
            selectAllMode: 'all',
            programmatic: false,
        };
    }

    private get vendorOptions(): PuiTableVendorOptions<void> {
        return {
            options: {
                sortable: [
                    'versionTime'
                ],
                requestFunction: async ({ limit, page, query, ascending }: PuiTableRequestFunctionParams): Promise<PuiTableWithServerResponse<ProcessDetailsTableValue[]>> => {
                    const defaultStatusFilter = [RED_STATUS, AMBER_STATUS];
                    const itemsPerPage = parseInt(limit.toString(), 10);
                    const params: MonitorDetailsDataParams = {
                        processType: this.process,
                        status: this.appliedStatusFilter.length ? this.appliedStatusFilter : defaultStatusFilter,
                        versionTimeSort: this.SORT[ascending],
                        subProcessType: this.subProces,
                        overviewValueType: this.overviewValueType,
                        itemsPerPage,
                        pageNo: page,
                        searchText: query,
                        fromDate: this.fromDate,
                        toDate: this.toDate
                    };
                    this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
                    this.source = this.axios.CancelToken.source();
                    await this.$store.dispatch('process/fetchSubprocessDetails', { params, cancelToken: this.source.token });

                    if (!this.subprocessesDetails || !this.subprocessesDetails.monitorDetails.length) {
                        return {
                            data: [],
                            count: 0,
                        };
                    }

                    //TODO: remove id when BE provides unique identifier
                    const data = await Promise.all(this.subprocessesDetails.monitorDetails.map(async (details, index) => ({
                        ...details,
                        data: JSON.parse(details.message ?? '{}'),
                        markAsReadBy: await this.getNameByKid(details.markAsReadBy),
                        process: details.process ? this.$t(PROCESSES[details.process].label) : '',
                        id: index
                    })));

                    return {
                        data,
                        count: this.subprocessesDetails.total,
                    };
                },
                headings: this.headings,
                selectable: this.tableSelectableConfiguration,
                perPage: 10,
                perPageValues: [10, 25, 50, 100],
                alwaysShowPerPageSelect: true,
                texts: {
                    limit: `${this.$t('table.limit')}:`
                }
            },
            columns: this.COLUMNS,
        }
    }

    private get subprocessesDetails(): MonitorDetailsResponse {
        return this.$store.getters['process/getSubprocesseDetails'];
    }

    private get isValidationProcess(): boolean {
        return Number(this.process) === VALIDATION;
    }

    private get isMarkAsReadButtonDisabled(): boolean {
        return this.selectedRows.length === 0 || this.isMarkAsReadLoading;
    }

    private get selectedTimeframe(): string {
        return `${this.getFormattedVersionTime(this.fromDate)} – ${this.getFormattedVersionTime(this.toDate)}`;
    }

    private get cachedUsers(): CachedUser[] {
        return this.$store.getters['comments/getCachedUsers'];
    }

    private created(): void {
        this.COLUMNS.forEach((column) => {
            this.headings[column] = this.$t(column);
        });

        if (this.$route.query.status) {
            const queryStatuses = Array.isArray(this.$route.query.status) ? this.$route.query.status : [this.$route.query.status];
            this.appliedStatusFilter = queryStatuses.map(e => Number(e));
        }
    }

    private mounted(): void {
        const tableRef = (this.$refs[this.DETAILS_TABLE_REF] as any)?.$children[0];
        tableRef.$on('select', this.tableItemsSelected);
        tableRef.$on('pagination', this.tablePagination);
    }

    private beforeDestroy(): void {
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);

        const tableRef = (this.$refs[this.DETAILS_TABLE_REF] as any)?.$children[0];
        tableRef.$off('select', this.tableItemsSelected);
        tableRef.$off('pagination', this.tablePagination);
    }

    private onActiveRouteChanged(linkItem: PuiNavigationToolbarClickEvent): void {
        linkItem.href && this.$router.push(linkItem.href);
    }

    private tableItemsSelected(selectedRows: MonitorDetails[]): void {
        this.selectedRows = [...selectedRows];
    }

    private tablePagination(): void {
        this.clearSelectedRows();
    }

    private clearSelectedRows(): void {
        const tableRef = (this.$refs[this.DETAILS_TABLE_REF] as any)?.$children[0];
        tableRef.resetSelectedRows();
    }

    private async getNameByKid(userKid: string | null): Promise<string> {
        if (!userKid) {
            return '-';
        }

        const result = this.cachedUsers.find((user: CachedUser) => user.userKid === userKid);
        if (result) {
            return result.name;
        }

        try {
            const result = await this.$store.dispatch('comments/getUserByKid', { userKid, signal: this.abortController.signal });
            if (result) {
                return result.name;
            }

            return userKid;
        } catch (e) {
            return userKid;
        }
    }

    private getLegendDetails(status: string): LegendDetails {
        const statusObject = PROCESS_STATUS[status] ? PROCESS_STATUS[status] : PROCESS_STATUS[GREY_STATUS];
        return {
            color: statusObject.color,
            label: this.$t(statusObject.statusColorName)
        }
    }

    private getFormattedVersionTime(date: string): string {
        return `${this.$options.filters?.puiFormatDateShort(date)} ${this.$options.filters?.puiFormatTimeMedium(date)}`;
    }

    private getFilter(): PuiFilterConfig {
        return {
            filters: [
                {
                    name: 'status',
                    displayName: this.$t('status'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: [
                            {
                                displayName: this.$t(PROCESS_STATUS[RED_STATUS].statusColorName),
                                value: RED_STATUS
                            },
                            {
                                displayName: this.$t(PROCESS_STATUS[AMBER_STATUS].statusColorName),
                                value: AMBER_STATUS
                            }
                        ]
                    },
                    selectedValues: {
                        options: this.appliedStatusFilter ?? []
                    },
                    appliedValues: {
                        options: this.appliedStatusFilter ?? []
                    }
                }
            ]
        }
    }

    private filterChanged(appliedValues: PuiFilterAppliedValues): void {
        this.appliedStatusFilter = appliedValues['status'];
        this.$router.replace({
            query: {
                ...this.$route.query,
                status: this.appliedStatusFilter.map(e => e.toString()),
            }
        });

        (this.$refs[this.DETAILS_TABLE_REF] as any).$children[0].getData();
    }

    private openCommentModal(commentRow: MonitorDetails): void {
        if (!commentRow.comment && !this.isAllowedToEditContent) {
            return;
        }

        this.selectedCommentRow = commentRow;
        this.isCommentLightboxOpen = true;
    }

    private async handleCloseCommentLightbox(refresh = false): Promise<void> {
        if (refresh) {
            await (this.$refs[this.DETAILS_TABLE_REF] as any).$children[0].getData();
        }
        this.selectedCommentRow = {} as MonitorDetails;
        this.isCommentLightboxOpen = false;
    }

    private async markAllAsRead(): Promise<void> {
        const itemsToMarkAsRead = this.selectedRows.map(row => ({
            markAsRead: true,
            detailId: row.detailId,
            markAsReadBy: this.currentUserKid,
            resultRyg: row.resultRyg ?? row.status.toString(),
            resultRygOverride: row.resultRygOverride,
        }));

        const payload: MarkAsReadPayload = {
            overviewValueType: this.overviewValueType,
            process: this.process,
            subProcess: this.subProces,
            fromDate: this.fromDate,
            toDate: this.toDate,
            itemsToMarkAsRead,
        };

        try {
            this.isMarkAsReadLoading = true;
            await this.$store.dispatch('process/markSubprocessDetailsAsRead', payload);
            this.clearSelectedRows();
            (this.$refs[this.DETAILS_TABLE_REF] as any)?.$children[0].getData();
            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('apiSuccess.markAsReadShort'),
                copy: this.$t('apiSuccess.markAsReadLong')
            });
        } catch (e) {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('apiError.markAsReadShort'),
                copy: this.$t('apiError.markAsReadLong')
            });
        } finally {
            this.isMarkAsReadLoading = false;
        }
    }
}
</script>

<style lang="less">
@import "../../variables.less";

.monitor-details {
    padding-top: 0.4rem;
    padding-bottom: 1.6rem;

    &__timeframe-wrapper {
        display: flex;
        margin: 1.6rem 3.2rem;
        background-color: @white;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 8px 0px;

        &__title {
            padding: 1.6rem 2.4rem;
            line-height: 1.2rem;
            display: flex;
            align-items: center;

            & > * {
                font-weight: bold;
            }
        }

        &__content {
            padding: 1.2rem 2.4rem;
            border-left: 1px solid @puicolor-severity-40;
            border-right: 1px solid @puicolor-severity-40;
        }
    }

    &__filter-row {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 1.2rem;
        align-items: flex-end;
        flex-wrap: wrap;

        @media screen and (min-width: @max-m-width) {
            flex-wrap: nowrap;
        }

        &__mark-as-read {
            height: 4rem;
            margin-top: 1.2rem;
            margin-bottom: 0.8rem;

            @media screen and (min-width: @max-m-width) {
                margin-top: 0;
            }
        }
    }

    &__table-wrapper {
        margin: 0 3.2rem;

        #vt-toggle-all {
            display: block;
        }
    }

    &__read-status-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.6rem;
    }

    &__table {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 8px 0px;
    }

    &__message {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__comment-cell {
        display: flex;
        white-space: nowrap;
    }

    &__comment-indicator {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 1.3rem;
        background: #FE0000;
        color: @white;
        text-align: center;
        line-height: 2.6rem;
        font-size: 1rem;
        cursor: pointer;
        margin-left: 1.3rem;
    }

    &__comment-link {
        &--disabled {
            cursor: none;
            color: @medium-grey;

            &:hover {
                cursor: unset;
                color: @medium-grey;
            }
        }
    }
}
</style>
