<template>
    <div class="flex-col profile-container">
        <div class="profile-details">
            <div class="pb-1">
                <button
                    type="button"
                    @click="copyToken()"
                    class="copy-token-button left-align"
                >
                    {{ $t('copyToken') }}
                    <font-awesome-icon icon="copy" />
                </button>
            </div>
            <div class="pb-1">
                <a
                    :href="swaggerURL"
                    target="_blank"
                    class="swagger-link"
                >
                    {{ $t('openSwagger') }}
                </a>
            </div>
            <div class="flex-col flex-3 pb-1">
                <div class="version">
                    {{ $t('version') }}&nbsp;{{ version }}
                </div>
                <div class="version ">
                    {{ $t('apiUrl') }}&nbsp;
                    <a
                        :href="apiUrl"
                        class="swagger-link"
                    >
                        {{ apiUrl }}
                    </a>
                </div>
            </div>
            <div class="pb-1">
                <button
                    type="button"
                    @click="sendToFeedbackForm()"
                    class="send-feedback-button"
                >
                    {{ $t('sendFeedback') }}
                </button>
            </div>
            <div class="pb-1">
                <button
                    type="button"
                    @click="sendToIncidentReporting()"
                    class="report-incident-button"
                >
                    {{ $t('reportIncident') }}
                </button>
            </div>
            <div>
                <button
                    type="button"
                    @click="logOut()"
                    class="logout-button"
                >
                    {{ $t('signOut') }}
                </button>
            </div>
        </div>
        <input
            type="text"
            class="copy-text"
            id="copyText"
        >
    </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import clickOutside from '@/directives/click-outside';
import {APP_SCOPE, sdk} from '@/utils/coode-sdk-config';

@Component({
    directives: {
        clickOutside,
    },
})
export default class UserProfile extends Vue {
    private version: string | undefined = process.env.VUE_APP_VERSION;
    private apiUrl: string | undefined = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL;
    private detailsToggled = false;

    private logOut(): void {
        sdk?.auth.logout()
    }

    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = sdk?.auth.getToken(APP_SCOPE);
            textElement.value = '';
            textElement.value = `Bearer ${token}`;
            textElement.select();
            document.execCommand('copy');
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    private offFocus(): void {
        if (this.detailsToggled) {
            this.detailsToggled = false;
        }
    }

    private sendToFeedbackForm(): void {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID ?? 0);
        const appStoreUrl = `${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/contact-page/${useCaseId}?isFeedback=true`;

        window.open(appStoreUrl, '_blank');
    }

    private sendToIncidentReporting(): void {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID ?? 0);
        const appStoreUrl = `${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/incident-page/${useCaseId}`;

        window.open(appStoreUrl, '_blank');
    }
}
</script>

<style scoped lang="less">
@import '../../../variables.less';
.profile-details {
    color: @dark-blue-grey;
    z-index: 10;
    font-size: 1.6rem;
    right: 0;
    .send-feedback-button {
        height: 3.5rem;
        width: 100%;
        transition: 0.2s ease-in;
        color: @white;
        background: @uniper-blue;
        border-radius: 3.2rem;
        &:hover {
            transition: 0.2s ease-in;
            background: @dark-blue;
        }
    }
    .report-incident-button {
        height: 3.5rem;
        width: 100%;
        color: @contextual-red;
        background: @light-grey;
        transition: 0.2s ease-in;
        padding: 0;
        border-radius: 0;
        &:hover {
            cursor: pointer;
            transition: 0.2s ease-in;
            background: @grey-lighter;
        }
    }
    .logout-button {
        height: 3.5rem;
        width: 100%;
        color: @dark-grey;
        background: @light-grey;
        transition: 0.2s ease-in;
        border-radius: 0;
        &:hover {
            transition: 0.2s ease-in;
            background: @grey-lighter;
        }
    }
    .copy-token-button {
        width: 100%;
        color: @dark-blue-grey;
        transition: 0.2s ease-in;
        box-shadow: none;
        padding: 0;
        :hover {
            transition: 0.2s ease-in;
        }
    }
    .swagger-link {
        color: @uniper-blue;
    }
}
.version {
    font-size: 1.3rem;
    margin: 0.3rem;
}
.copy-text {
    position: fixed;
    top: -99999rem;
}
</style>
