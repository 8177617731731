<template>
    <div
        id="app"
        v-if="isAppReady"
    >
        <header-wrapper />
        <navbar-component />
        <router-view />
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import NavbarComponent from '@/components/navbar/navbar.vue';
import './main.less';
import { sdk } from './utils/coode-sdk-config';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        Snackbar,
        Loader,
        NavbarComponent
    },
})
export default class App extends Vue {
    private isAppReady = false

    public async created(): Promise<void> {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
        await sdk?.core.userAccessManagement.setPermissions(useCaseId);

        this.isAppReady = true;
    }
}
</script>

<style lang="less">
</style>
