import { Application } from '@/models';
import { sdk } from '@/utils/coode-sdk-config';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService {
    public async getApps(): Promise<Application[]> {
        return (await sdk?.core.metaData.request.api.get<{result: {items: Application[]}}>('/use-cases'))
            .data
            .result
            .items;
    }
}
