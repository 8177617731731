<template>
    <div class="calendar-list-view">
        <table class="calendar-list-view__table">
            <thead>
                <tr>
                    <th class="calendar-list-view__table__unit-heading-cell" />
                    <th
                        v-for="(day, index) in daysInDateRange"
                        :key="index"
                        class="calendar-list-view__table__status-heading-cell"
                    >
                        {{ day }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="unit in config.items"
                    :key="unit.unitSid"
                >
                    <td class="calendar-list-view__table__unit-data-cell">
                        {{ `${unit.unitName} (${unit.unitSid})` }}
                    </td>
                    <td
                        v-for="(day, index) in unit.days"
                        :key="index"
                        :class="[{
                            'calendar-list-view__table__status-data-cell--first': index === 0,
                            'calendar-list-view__table__status-data-cell--last': index === days.length - 1,
                        }, 'calendar-list-view__table__status-data-cell']"
                    >
                        <div
                            :style="getStyleForContentCell(day.statusColor)"
                            :class="[{
                                'calendar-list-view__table__status-data-cell__item--first': index === 0,
                                'calendar-list-view__table__status-data-cell__item--last': index === days.length - 1,
                            }, 'calendar-list-view__table__status-data-cell__item']"
                            @click="unitClicked(day.statusColor, {
                                day: day.day,
                                unitName: unit.unitName,
                                unitSid: unit.unitSid,
                            })"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { CalendarViewConfiguration } from '@/models/interfaces';
import { AMBER_STATUS, PROCESS_STATUS, RED_STATUS } from '@/config/process-statuses';
import { eachDayOfInterval } from 'date-fns'
import { format } from '@enerlytics/time-helper/dist/date-fns';

export type CalendarUnitClickPayload = {
    day: string;
    unitName: string;
    unitSid: number;
};

@Component({})
export default class CalendarListViewComponent extends Vue {
    private readonly CLICKABLE_STATUSES = [RED_STATUS, AMBER_STATUS];

    /**
     * This prop is used to know the configuration for the calendar view.
     */
    @Prop({ required: true })
    private config!: CalendarViewConfiguration

    private get days(): string[] {
        return this.config.items?.[0].days.map(e => e.day) ?? [];
    }

    private get daysInDateRange(): string[] {
        if (this.days.length === 0) {
            return [];
        }

        const firstDate = new Date(this.days[0]) ?? new Date();
        const lastDate = new Date(this.days[this.days.length - 1]) ?? new Date();

        return eachDayOfInterval({
            start: firstDate,
            end: lastDate
        }).map(date => format(date, 'd'));
    }

    private unitClicked(statusColor: number, payload: CalendarUnitClickPayload): void {
        if (!this.CLICKABLE_STATUSES.includes(statusColor)) {
            return;
        }

        this.$emit('click:unit', payload);
    }

    private getStyleForContentCell(statusColor: number): Record<string, string> {
        if (!PROCESS_STATUS[statusColor]) {
            return {};
        }

        const style = {
            backgroundColor: PROCESS_STATUS[statusColor].color,
        };

        if (this.CLICKABLE_STATUSES.includes(statusColor)) {
            style['cursor'] = 'pointer';
        }

        return style;
    }
}
</script>

<style lang="less">
@import '~@/variables.less';

.calendar-list-view {
    &__table {
        width: 100%;
        background-color: white;
        border-collapse: collapse;

        &__unit-heading-cell {
            width: 1%;
        }

        &__unit-data-cell {
            width: 1%;
            white-space: nowrap;
            text-align: left;
        }

        &__status-heading-cell {
            min-width: 3rem;
        }

        &__status-data-cell {
            min-width: 3rem;
            padding: 1rem 0;
            border: @lighter-grey 1px solid;

            &__item {
                min-height: 3rem;
                min-width: 3rem;

                &--first {
                    border-top-left-radius: 0.6rem;
                    border-bottom-left-radius: 0.6rem;
                }

                &--last {
                    border-top-right-radius: 0.6rem;
                    border-bottom-right-radius: 0.6rem;
                }
            }

            &--first {
                padding-left: 0.5rem;
            }

            &--last {
                padding-right: 0.5rem;
            }
        }
    }
}
</style>
