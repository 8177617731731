import { sdk } from '@/utils/coode-sdk-config'
import { Api } from '@coode/fe-sdk'

export default class BaseService {
    protected resourcePath = '';

    constructor(resourcePath?: string) {
        this.resourcePath = resourcePath ?? '';
    }

    protected get api(): Api {
        return sdk?.backend.request.api;
    }
}
