<template>
    <pui-lightbox
        :ref="REF_CONSTANTS.LIGHTBOX"
        :default-header-label="lightboxTitle"
        :default-footer-cancel-label="$t('thresholdsTab.modal.cancel')"
        :default-footer-confirm-label="$t('thresholdsTab.modal.save')"
        :default-footer-confirm-disabled="!isFormSubmittable"
        :on-confirm-callback="lightboxConfirm"
        :on-before-close-callback="lightboxBeforeClose"
        :fit-content="true"
    >
        <pui-loader :promise="thresholdPromise">
            <pui-form
                :notifications="errorNotifications"
                aria-label="threshold-form"
                class="threshold-form"
                @submit.prevent
            >
                <div v-pui-form-grid-row>
                    <pui-form-group
                        :label="$t(formData.labels.lowerThreshold)"
                        :is-valid="formData.validation.lowerThreshold"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.ONE_SIXTH"
                    >
                        <pui-form-input-field
                            v-model="formData.values.lowerThreshold"
                            :is-valid="formData.validation.lowerThreshold"
                            :required="true"
                            :min="MIN_THRESHOLD_VALUE"
                            :max="MAX_THRESHOLD_VALUE"
                            :step="THRESHOLD_STEP"
                            type="number"
                            @input="validateForm"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.lowerThreshold }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        :label="$t(formData.labels.upperThreshold)"
                        :is-valid="formData.validation.upperThreshold"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.ONE_SIXTH"
                    >
                        <pui-form-input-field
                            v-model="formData.values.upperThreshold"
                            :is-valid="formData.validation.upperThreshold"
                            :required="true"
                            :min="MIN_THRESHOLD_VALUE"
                            :max="MAX_THRESHOLD_VALUE"
                            :step="THRESHOLD_STEP"
                            type="number"
                            @input="validateForm"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.upperThreshold }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        :label="$t(formData.labels.validFrom)"
                        :is-valid="formData.validation.validFrom"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.ONE_THIRD"
                    >
                        <pui-form-input-field
                            v-model="formData.values.validFrom"
                            :is-valid="formData.validation.validFrom"
                            :required="true"
                            type="date"
                            @input="validateForm"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.validFrom }}
                        </template>
                    </pui-form-group>
                    <pui-form-group
                        :label="$t(formData.labels.validTo)"
                        :is-valid="formData.validation.validTo"
                        v-pui-form-grid-column="GRID_COLUMN_SPAN.ONE_THIRD"
                    >
                        <pui-form-input-field
                            v-model="formData.values.validTo"
                            :is-valid="formData.validation.validTo"
                            :required="true"
                            type="date"
                            @input="validateForm"
                        />
                        <template #error-message>
                            {{ formData.errorMessages.validTo }}
                        </template>
                    </pui-form-group>
                </div>
            </pui-form>
        </pui-loader>
    </pui-lightbox>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { PuiFormNotification, PuiLightbox } from '@/models/pebble-ui';
import { DAY_AHEAD, SUB_PROCESSES } from '@/config/processes';
import {
    AddThresholdPayload,
    EditThresholdPayload,
    GetThresholdsSubprocessItem,
    ThresholdItem
} from '@/models/services/thresholds';
import { endOfDay, format, formatISO, startOfDay } from '@enerlytics/time-helper/dist/date-fns';
import { ThresholdFormConfig, ThresholdFormData, ThresholdModalMode } from '@/models/interfaces';
import { areIntervalsOverlapping, Interval, isAfter, isSameDay, isWithinInterval } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({})
export default class EditThresholdModalComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly MIN_THRESHOLD_VALUE = 0;
    private readonly MAX_THRESHOLD_VALUE = 20;
    private readonly THRESHOLD_STEP = 1;
    private readonly DATE_FORMAT = 'yyyy-MM-dd';

    private readonly GRID_COLUMN_SPAN = {
        ONE_SIXTH: 2,
        ONE_THIRD: 4,
    } as const;

    private readonly FORM_ERROR_MESSAGES_KEY = {
        VALUE_REQUIRED: 'formError.valueRequired',
        VALUE_BETWEEN: 'formError.valueBetween',
        VALUE_LOWER: 'formError.valueLower',
        VALUE_BEFORE: 'formError.valueBefore',
        VALUE_OVERLAPPING: 'formError.valueOverlapping',
        VALUE_INTEGER: 'formError.valueInteger',
    } as const;

    private readonly CONFIG: ThresholdFormConfig = {
        [ThresholdModalMode.ADD]: {
            titleLabel: 'thresholdsTab.modal.addTitle',
            submitFn: this.addSubmitFn,
        },
        [ThresholdModalMode.EDIT]: {
            titleLabel: 'thresholdsTab.modal.editTitle',
            submitFn: this.editSubmitFn,
        },
    } as const;

    private thresholdPromise: Promise<void> | null = Promise.resolve();
    private isFormSubmitting = false;
    private errorNotifications: PuiFormNotification[] = [];
    private currentMode: ThresholdModalMode = ThresholdModalMode.ADD;
    private currentSubProcess = DAY_AHEAD;
    private currentThresholdItem: ThresholdItem | null = null;

    private formData: ThresholdFormData = {
        labels: {
            lowerThreshold: 'thresholdsTab.lowerThreshold',
            upperThreshold: 'thresholdsTab.upperThreshold',
            validFrom: 'thresholdsTab.validFrom',
            validTo: 'thresholdsTab.validTo',
        },
        initialValues: {
            lowerThreshold: '',
            upperThreshold: '',
            validFrom: '',
            validTo: '',
        },
        values: {
            lowerThreshold: '',
            upperThreshold: '',
            validFrom: '',
            validTo: '',
        },
        validation: {
            lowerThreshold: true,
            upperThreshold: true,
            validFrom: true,
            validTo: true,
        },
        validators: {
            lowerThreshold: this.lowerThresholdValidationFn,
            upperThreshold: this.upperThresholdValidationFn,
            validFrom: this.validFromValidationFn,
            validTo: this.validToValidationFn,
        },
        errorMessages: {
            lowerThreshold: undefined,
            upperThreshold: undefined,
            validFrom: undefined,
            validTo: undefined,
        }
    };

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    private get thresholdItemsForSubprocess(): ThresholdItem[] {
        const thresholdsData: GetThresholdsSubprocessItem[] = this.$store.getters['thresholds/getThresholds'];
        let thresholds = thresholdsData.find(e => e.subProcess === this.currentSubProcess)?.thresholds ?? [];

        if (this.currentMode === ThresholdModalMode.EDIT) {
            thresholds = thresholds.filter(e => e.insertTs !== this.currentThresholdItem?.insertTs);
        }

        return thresholds;
    }

    private get currentUserKid(): string {
        return this.$store.getters['user/getUserInformation']?.kid ?? '';
    }

    private get lightboxTitle(): string {
        return this.$t(this.CONFIG[this.currentMode].titleLabel, { subProcess: this.$t(SUB_PROCESSES[this.currentSubProcess].label) });
    }

    private get isFormClean(): boolean {
        return Object.keys(this.formData.values)
            .map(key => this.formData.values[key] === this.formData.initialValues[key])
            .reduce((previousValue, currentValue) => previousValue && currentValue, true);
    }

    private get isFormValid(): boolean {
        return Object.keys(this.formData.validation).every(key => this.formData.validation[key]);
    }

    private get isFormSubmittable(): boolean {
        return this.isFormValid
            && !this.isFormSubmitting
            && !this.isFormClean;
    }

    public openModalForAdd(subProcess: number): void {
        this.currentMode = ThresholdModalMode.ADD;
        this.currentSubProcess = subProcess;

        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }

    public openModalForEdit(subProcess: number, thresholdItem: ThresholdItem): void {
        this.currentMode = ThresholdModalMode.EDIT;
        this.currentSubProcess = subProcess;

        this.loadThresholdItemData(thresholdItem);

        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }

    private loadThresholdItemData(thresholdItem: ThresholdItem): void {
        this.currentThresholdItem = thresholdItem;

        this.formData.values.upperThreshold = thresholdItem.upperThreshold.toString();
        this.formData.values.lowerThreshold = thresholdItem.lowerThreshold.toString();
        this.formData.values.validFrom = format(utcToZonedTime(thresholdItem.validFrom, 'UTC'), this.DATE_FORMAT);
        this.formData.values.validTo = format(utcToZonedTime(thresholdItem.validTo, 'UTC'), this.DATE_FORMAT);

        Object.keys(this.formData.values).forEach(key => {
            this.formData.initialValues[key] = this.formData.values[key];
        });
    }

    private clearFormData(): void {
        this.currentThresholdItem = null;
        this.isFormSubmitting = false;
        this.errorNotifications = [];

        Object.keys(this.formData.values).forEach(key => {
            this.formData.values[key] = '';
            this.formData.initialValues[key] = '';
            this.formData.validation[key] = true;
            this.formData.errorMessages[key] = undefined;
        });
    }

    private validateForm(): void {
        Object.keys(this.formData.validators).forEach(key => {
            const validationError = this.formData.validators[key](this.formData.values[key]);

            this.formData.validation[key] = !validationError;
            this.formData.errorMessages[key] = validationError;
        });
    }

    private isValueInsideInterval(value: string): boolean {
        return Number(value) >= this.MIN_THRESHOLD_VALUE && Number(value) <= this.MAX_THRESHOLD_VALUE;
    }

    private isValueGreaterThanLowerThreshold(value: string): boolean {
        return Number(value) > Number(this.formData.values.lowerThreshold);
    }

    private isDateGreaterThanValidFrom(value: string): boolean {
        const valueDate = new Date(value);
        const validFromDate = new Date(this.formData.values.validFrom);

        return isAfter(valueDate, validFromDate) || isSameDay(valueDate, validFromDate);
    }

    private isDateRangeNotOverlapping(): boolean {
        const validFrom = this.startOfDayStripToUtc(new Date(this.formData.values.validFrom));
        const validTo = this.endOfDayStripToUtc(new Date(this.formData.values.validTo));

        let valid = true;

        // Check whether the form input is single date, or interval
        if (isSameDay(validFrom, validTo)) {
            // Form input is single date
            this.thresholdItemsForSubprocess.forEach(item => {
                const itemValidFrom = this.startOfDayStripToUtc(new Date(item.validFrom));
                const itemValidTo = this.endOfDayStripToUtc(new Date(item.validTo));

                // Check whether the threshold item is single date, or interval
                if (isSameDay(itemValidFrom, itemValidTo)) {
                    // Check if form single date is same as threshold single date
                    valid = valid && !isSameDay(validFrom, itemValidFrom);
                } else {
                    // Check if form single date is in threshold interval
                    const itemInterval: Interval = {
                        start: itemValidFrom,
                        end: itemValidTo
                    };

                    valid = valid && !isWithinInterval(validFrom, itemInterval);
                }
            });
        } else {
            // Form input is interval
            const interval: Interval = {
                start: validFrom,
                end: validTo,
            };

            this.thresholdItemsForSubprocess.forEach(item => {
                const itemValidFrom = new Date(item.validFrom);
                const itemValidTo = new Date(item.validTo);

                // Check whether the threshold item is single date, or interval
                if (isSameDay(itemValidFrom, itemValidTo)) {
                    // Check if threshold single date is in form interval
                    valid = valid && !isWithinInterval(itemValidFrom, interval);
                } else {
                    // Check if threshold interval is overlapping form interval
                    const itemInterval: Interval = {
                        start: itemValidFrom,
                        end: itemValidTo
                    };

                    valid = valid && !areIntervalsOverlapping(interval, itemInterval);
                }
            });
        }

        return valid;
    }

    private lowerThresholdValidationFn(input: string): string | undefined {
        if (input === '') {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_REQUIRED);
        }

        if (!Number.isInteger(Number(input))) {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_INTEGER);
        }

        if (!this.isValueInsideInterval(input)) {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_BETWEEN, { interval1: this.MIN_THRESHOLD_VALUE, interval2: this.MAX_THRESHOLD_VALUE });
        }
    }

    private upperThresholdValidationFn(input: string): string | undefined {
        if (input === '') {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_REQUIRED);
        }

        if (!Number.isInteger(Number(input))) {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_INTEGER);
        }

        if (!this.isValueInsideInterval(input)) {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_BETWEEN, { interval1: this.MIN_THRESHOLD_VALUE, interval2: this.MAX_THRESHOLD_VALUE });
        }

        if (!this.isValueGreaterThanLowerThreshold(input)) {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_LOWER);
        }
    }

    private validFromValidationFn(input: string): string | undefined {
        if (input === '') {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_REQUIRED);
        }
    }

    private validToValidationFn(input: string): string | undefined {
        if (input === '') {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_REQUIRED);
        }

        if (!this.isDateGreaterThanValidFrom(input)) {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_BEFORE);
        }

        if (!this.isDateRangeNotOverlapping()) {
            return this.$t(this.FORM_ERROR_MESSAGES_KEY.VALUE_OVERLAPPING);
        }
    }

    private async addSubmitFn(): Promise<void> {
        const validFrom = formatISO(this.startOfDayStripToUtc(new Date(this.formData.values.validFrom)));
        const validTo = formatISO(this.endOfDayStripToUtc(new Date(this.formData.values.validTo)));

        const payload: AddThresholdPayload = {
            subProcess: this.currentSubProcess,
            lowerThreshold: Number(this.formData.values.lowerThreshold),
            upperThreshold: Number(this.formData.values.upperThreshold),
            userKid: this.currentUserKid,
            validFrom,
            validTo,
        };

        return this.$store.dispatch('thresholds/addThreshold', payload);
    }

    private async editSubmitFn(): Promise<void> {
        if (!this.currentThresholdItem?.insertTs) {
            throw Error();
        }

        const validFrom = formatISO(this.startOfDayStripToUtc(new Date(this.formData.values.validFrom)));
        const validTo = formatISO(this.endOfDayStripToUtc(new Date(this.formData.values.validTo)));

        const payload: EditThresholdPayload = {
            insertTs: this.currentThresholdItem.insertTs,
            subProcess: this.currentSubProcess,
            lowerThreshold: Number(this.formData.values.lowerThreshold),
            upperThreshold: Number(this.formData.values.upperThreshold),
            userKid: this.currentUserKid,
            validFrom,
            validTo,
        };

        return this.$store.dispatch('thresholds/editThreshold', payload);
    }

    private lightboxConfirm(): void {
        this.isFormSubmitting = true;
        this.errorNotifications = [];

        this.validateForm();
        if (this.isFormValid) {
            this.CONFIG[this.currentMode].submitFn()
                .then(() => {
                    this.$emit('reload:thresholds');
                    this.$refs[REF_CONSTANTS.LIGHTBOX].close();
                })
                .catch(() => {
                    this.isFormSubmitting = false;
                    this.errorNotifications.push({
                        type: 'error',
                        title: this.$t('formError.dataCouldNotBeSaved'),
                        text: this.$t('apiError.errorSavingThresholds'),
                    });
                });
        } else {
            this.isFormSubmitting = false;
            this.errorNotifications.push({
                type: 'error',
                title: this.$t('formError.dataCouldNotBeSaved'),
                text: this.$t('formError.checkHighlightedInputs'),
            });
        }
    }

    private lightboxBeforeClose(): void {
        this.clearFormData();
    }

    private startOfDayStripToUtc(date: Date): Date {
        return zonedTimeToUtc(startOfDay(date), 'Etc/UTC');
    }

    private endOfDayStripToUtc(date: Date): Date {
        return zonedTimeToUtc(endOfDay(date), 'Etc/UTC');
    }
}
</script>

<style lang="less">
.threshold-form {
    padding-top: 2rem;
    padding-bottom: 3rem;

    min-width: 75vw;
}
</style>
