var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
        'header-wrapper',
        {
            'header-wrapper--dev': _vm.currentEnvironment === 'dev' || _vm.currentEnvironment === 'development',
            'header-wrapper--uat': _vm.currentEnvironment === 'uat',
            'header-wrapper--prd': _vm.currentEnvironment === 'prd',
        }
    ]},[_c('adam-header-bar',{attrs:{"username":_vm.userInformation.name,"emailaddress":_vm.userInformation.email},domProps:{"showNotifications":false},on:{"apps-click":_vm.clickDrawer}},[_c('span',{attrs:{"slot":"headerContent"},slot:"headerContent"},[_c('img',{staticClass:"coode-logo",attrs:{"alt":"logo-coode-white","src":require("adam.ui-core/assets/brand/logo-coode-white.svg")}}),_c('span',{staticClass:"adam-header-bar adam-header__separator"}),_c('h4',[_vm._v("Generation Data Checks")])]),_c('span',{attrs:{"slot":"right"},slot:"right"},[_c('div',{staticClass:"language-selector"},[_c('pui-form-select',{attrs:{"label":"","options":_vm.languageOptions,"value":_vm.DEFAULT_LOCALE},on:{"change":_vm.setLanguage},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('img',{staticClass:"language-selector__icon",attrs:{"src":_vm.getLanguageFlag(slotProps.option.value)}}),_c('pui-list-option',{staticClass:"language-selector__option",attrs:{"label":slotProps.option.label}})]}}])})],1)]),_c('div',{attrs:{"slot":"profileDetails"},slot:"profileDetails"},[_c('user-profile')],1)]),_c('app-drawer',{ref:"appDrawer",domProps:{"data":_vm.applications,"labels":_vm.getAppDrawerLabels,"onClick":_vm.handleAppClick}},[_c('span',{staticClass:"app-logo",attrs:{"slot":"appLogo"},slot:"appLogo"},[_c('img',{attrs:{"src":require("adam.ui-core/assets/brand/logo-coode-rgb.svg")}}),_c('img',{attrs:{"src":require("adam.ui-core/assets/brand/logo-enerlytics-rgb.svg")}})])]),_c('snackbar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }