<template>
    <div
        :class="[
            'header-wrapper',
            {
                'header-wrapper--dev': currentEnvironment === 'dev' || currentEnvironment === 'development',
                'header-wrapper--uat': currentEnvironment === 'uat',
                'header-wrapper--prd': currentEnvironment === 'prd',
            }
        ]"
    >
        <adam-header-bar
            :username="userInformation.name"
            :emailaddress="userInformation.email"
            @apps-click="clickDrawer"
            :show-notifications.prop="false"
        >
            <span slot="headerContent">
                <img
                    class="coode-logo"
                    alt="logo-coode-white"
                    src="~adam.ui-core/assets/brand/logo-coode-white.svg"
                >
                <span class="adam-header-bar adam-header__separator" />
                <h4>Generation Data Checks</h4>
            </span>
            <span slot="right">
                <div class="language-selector">
                    <pui-form-select
                        label=""
                        :options="languageOptions"
                        :value="DEFAULT_LOCALE"
                        @change="setLanguage"
                    >
                        <template slot-scope="slotProps">
                            <img
                                :src="getLanguageFlag(slotProps.option.value)"
                                class="language-selector__icon"
                            >
                            <pui-list-option
                                :label="slotProps.option.label"
                                class="language-selector__option"
                            />
                        </template>
                    </pui-form-select>
                </div>
            </span>
            <div slot="profileDetails">
                <user-profile />
            </div>
        </adam-header-bar>
        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :on-click.prop="handleAppClick"
        >
            <span
                slot="appLogo"
                class="app-logo"
            >
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
                <img src="~adam.ui-core/assets/brand/logo-enerlytics-rgb.svg">
            </span>
        </app-drawer>
        <snackbar />
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import UserProfile from './user-profile/user-profile.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { AppService } from '@/services';
import { Application } from '@/models';
import { PuiSelectOptions } from '@/models/pebble-ui';
import Snackbar from '@/components/snackbar/snackbar.vue';
import { EventBus } from '@/utils';
import { DEFAULT_LOCALE } from '@/config/consts';
import { languageOptions, getLanguageFlag, loadLanguageAsync } from '@/utils/i18n';
import { UserInformation } from '@/store/modules/user.module';

@Component({
    name: 'header-wrapper',
    components: {
        userProfile: UserProfile,
        snackbar: Snackbar,
    },
})
export default class HeaderWrapper extends Vue {
    private appService: AppService = new AppService();
    private applications: MenuEntry[] = [];

    private DEFAULT_LOCALE = DEFAULT_LOCALE;

    private get userInformation(): UserInformation {
        return this.$store.getters['user/getUserInformation'];
    }

    private get currentEnvironment(): string {
        return process.env.VUE_APP_ENVIRONMENT || '';
    }

    private async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.loadApps();
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = await this.appService.getApps();
            this.applications = this.processApps(apps);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications');
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a?.label?.toLowerCase();
            const nameB = b?.label?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private getLanguageFlag = getLanguageFlag;

    private async setLanguage(lang: string): Promise<string | void> {
        await loadLanguageAsync(lang);

        // TODO:
        // might just remove this when finding a proper way
        // to set PebbleUI `dateTimeLocale`
        location.reload();
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$t('appsWithAccess'), miscellaneous: this.$t('appsWithoutAccess') };
    }

    get languageOptions(): PuiSelectOptions { return languageOptions() }
}
</script>

<style lang="less">
@import '../../variables.less';
.app-logo {
    img {
        height: 100%;
        margin-right: 1rem;
    }
}
.coode-logo {
    height: 100%;
    padding: 0.6rem 0;
}
.logout-button {
    height: 3.5rem;
    width: 100%;
    color: @dark-grey;
    background: @light-grey;
    transition: 0.2s ease-in;
    border-radius: 0;
    &:hover {
        transition: 0.2s ease-in;
        background: #E0E0E0;
    }
}

adam-header-bar {
    [slot="right"] {
        .language-selector {
            min-width: 18rem;

            &__icon {
                vertical-align: middle;
                padding: 0 0.5rem 0 0;
            }
            &__option {
                display: inline-block;

                /deep/ .pui-list-option__label {
                    line-height: 3rem;
                }
            }
        }
    }
}

.header-wrapper {
    &--dev {
        .adam-header {
            --header-bar-bg: ~'@{coode-header-dev}';

            &__separator {
                background-color: @coode-header-dev-contrast;
            }

            &__logo.adam-header-bar:hover {
                --fill: @coode-header-dev-contrast;
            }

            .adam-header-user {
                &__icon {
                    &:hover, &--focused {
                        background-color: @coode-header-dev-contrast;
                        --stroke: white;
                    }
                }
            }

            ::selection {
                background: @coode-header-dev-contrast;
            }
        }
    }

    &--uat {
        .adam-header {
            --header-bar-bg: @coode-header-uat;

            &__separator {
                background-color: @coode-header-uat-contrast;
            }

            &__logo.adam-header-bar:hover {
                --fill: @coode-header-uat-contrast;
            }

            .adam-header-user {
                &__icon {
                    &:hover, &--focused {
                        background-color: @coode-header-uat-contrast;
                        --stroke: white;
                    }
                }
            }

            ::selection {
                background: @coode-header-uat-contrast;
            }
        }
    }
}
</style>
