<template>
    <div class="reports-page">
        <pui-headline
            type="h1"
            looks-like="h3"
        >
            {{ $t('generateErrorReport') }}
        </pui-headline>
        <pui-form-inline-notification
            v-if="!isRangeValid"
            :title="$t('reportRangeError.title')"
            :text="$t('reportRangeError.message')"
            type="error"
            class="reports-page__range-error-notification"
        />
        <div class="reports-page__filters">
            <div class="reports-page__filters__box">
                <div
                    v-for="(process, index) in processes"
                    :key="index"
                    class="reports-page__filters__box__option"
                >
                    <pui-form-checkbox
                        v-model="selectedProcesses"
                        :label="$t(process.label)"
                        :value="Number(index)"
                        @change="processesChanged"
                    />
                </div>
            </div>
            <div class="reports-page__filters__box">
                <div
                    v-for="(subProcess, index) in subProcesses"
                    :key="index"
                    class="reports-page__filters__box__option"
                >
                    <pui-form-checkbox
                        v-model="selectedSubProcesses"
                        :is-disabled="checkIfSubprocessIsDisabled(Number(index))"
                        :label="$t(subProcess.label)"
                        :value="Number(index)"
                    />
                </div>
            </div>
        </div>
        <div class="reports-page__button">
            <pui-button
                :disabled="exportDisabled"
                @click="exportReportsExcel"
            >
                {{ $t('exportExcel') }}
            </pui-button>
        </div>
        <a
            :ref="reportsExcelDownload"
            :href="reportsExcelUrl"
            :download="reportsExcel.name"
        />
        <pui-date-bottom-bar
            :range="range"
            :selectable-range="selectableRange"
            :options-selector-configuration="optionsSelectorConfiguration"
            :options-selector-model.sync="optionsSelectorModel"
            :show-timezone="false"
            @update:range="rangeChanged"
        />
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { PuiDateBottomBarRange, PuiDateRangeOptions } from '@/models/pebble-ui';
import { endOfDay, startOfDay, differenceInCalendarMonths, subDays, subMonths, subWeeks, subYears } from '@enerlytics/time-helper/dist/date-fns';
import { QMDI_NAMESPACE } from '@/config/consts';
import { DATA_SUPPLY, MASTER_DATA, PROCESSES, SUB_PROCESSES } from '@/config/processes';
import { FileResponse, ReportsExcelParams, VueWithRefs } from '@/models/interfaces';

@Component({})
export default class ReportsComponent extends Vue {
    private selectedProcesses: number[] = [];
    private selectedSubProcesses: number[] = [];
    private processes = PROCESSES;
    private subProcesses = SUB_PROCESSES;
    private reportsExcelUrl = '';
    private reportsExcelDownload = 'reportsExcelDownload';
    private isDownloadInProgress = false;

    private readonly REPORT_RANGE_KEY = `${QMDI_NAMESPACE}reportsRange`;
    private readonly END_OF_DAY = endOfDay(new Date());
    private readonly MAXIMUM_RANGE_PERIOD_IN_MONTHS = 18;

    private abortController = new AbortController();

    private optionsSelectorModel = null;
    private getActiveDateTimeFrom: string | null = null;
    private getActiveDateTimeTo: string | null = null;

    private get reportsExcel(): FileResponse {
        return this.$store.getters['reports/getReportsExcel'];
    }

    private get exportDisabled(): boolean {
        return this.selectedProcesses.length === 0 || this.selectedSubProcesses.length === 0 || !this.isRangeValid || this.isDownloadInProgress;
    }

    private get excludedSubProcesses(): number[] {
        const excludedSubProcesses: number[] = [];

        if (!this.selectedProcesses.includes(DATA_SUPPLY)) {
            excludedSubProcesses.push(MASTER_DATA);
        }

        return excludedSubProcesses;
    }

    private get optionsSelectorConfiguration(): PuiDateRangeOptions {
        return [
            {
                label: this.$t('report'),
                secondaryLabel: this.$t('report'),
                value: {
                    start: startOfDay(subDays(this.END_OF_DAY, 3)),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
            {
                label: this.$t('dayShort'),
                secondaryLabel: this.$t('day'),
                value: {
                    start: startOfDay(subDays(this.END_OF_DAY, 1)),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
            {
                label: this.$t('weekShort'),
                secondaryLabel: this.$t('week'),
                value: {
                    start: startOfDay(subWeeks(this.END_OF_DAY, 1)),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
            {
                label: this.$t('monthShort'),
                secondaryLabel: this.$t('month'),
                value: {
                    start: startOfDay(subMonths(this.END_OF_DAY, 1)),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
        ];
    }

    private get selectableRange(): PuiDateBottomBarRange {
        return {
            start: startOfDay(subYears(this.END_OF_DAY, 5)).toISOString(),
            end: this.END_OF_DAY.toISOString(),
        };
    }

    private get range(): PuiDateBottomBarRange {
        return {
            start: this.getActiveDateTimeFrom,
            end: this.getActiveDateTimeTo,
        };
    }

    private get isRangeValid(): boolean {
        if (!this.getActiveDateTimeTo || !this.getActiveDateTimeFrom) {
            return false;
        }

        return differenceInCalendarMonths(new Date(this.getActiveDateTimeTo), new Date(this.getActiveDateTimeFrom)) <= this.MAXIMUM_RANGE_PERIOD_IN_MONTHS;
    }

    created(): void {
        if (sessionStorage.getItem(this.REPORT_RANGE_KEY) !== null) {
            this.getActiveDateTimeFrom = JSON.parse(sessionStorage.getItem(this.REPORT_RANGE_KEY) as string).start;
            this.getActiveDateTimeTo = JSON.parse(sessionStorage.getItem(this.REPORT_RANGE_KEY) as string).end;
            let existingModel = null;
            for (const model of this.optionsSelectorConfiguration) {
                if (this.getActiveDateTimeFrom && this.getActiveDateTimeTo &&
                    model.value.start.toISOString() === new Date(this.getActiveDateTimeFrom).toISOString() &&
                    model.value.end.toISOString() === new Date(this.getActiveDateTimeTo).toISOString()) {
                    existingModel = model;
                    break;
                }
            }
            if (existingModel) {
                existingModel.selected = true;
            }
        } else {
            this.optionsSelectorConfiguration[1].selected = true;
        }
    }

    private rangeChanged({ start, end }: PuiDateBottomBarRange): void {
        this.getActiveDateTimeFrom = start;
        this.getActiveDateTimeTo = end;

        sessionStorage.setItem(this.REPORT_RANGE_KEY, JSON.stringify(this.range));
    }

    private processesChanged(): void {
        if (!this.selectedProcesses.includes(DATA_SUPPLY) && this.selectedSubProcesses.includes(MASTER_DATA)) {
            this.selectedSubProcesses = this.selectedSubProcesses.filter(e => e !== MASTER_DATA);
        }
    }

    private checkIfSubprocessIsDisabled(subProcess: number): boolean {
        return this.excludedSubProcesses.includes(subProcess);
    }

    private async exportReportsExcel(): Promise<void> {
        this.isDownloadInProgress = true;
        try {
            await this.fetchReportsExcel();
            this.forceFileDownload(this.reportsExcel.content);
            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('apiSuccess.exportSuccessful'),
                copy: this.$t('apiSuccess.dataHasBeenExported')
            });
        } catch(e) {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('apiError.exportFailed'),
                copy: this.$t('apiError.internalErrorExport')
            });
        }
        this.isDownloadInProgress = false;
    }

    private forceFileDownload(data: Blob): void {
        this.reportsExcelUrl = window.URL.createObjectURL(new Blob([data]));

        this.$nextTick(() => {
            const downloadRef = this.$refs[this.reportsExcelDownload] as VueWithRefs;
            downloadRef.click();
        });
    }

    private async fetchReportsExcel(): Promise<void> {
        if (this.range.start && this.range.end) {
            this.abortController.abort();
            this.abortController = new AbortController();
            const params = {
                fromDate: this.range.start,
                toDate: this.range.end,
                subProcess: this.selectedSubProcesses,
                process: this.selectedProcesses,
            }
            await this.$store.dispatch('reports/fetchReportsExcel', { params: params, signal: this.abortController.signal } as { params: ReportsExcelParams; signal: AbortSignal });
        }
    }
}
</script>

<style lang="less">
@import '../../variables.less';

.reports-page {
    padding: 3.2rem;
    padding-bottom: 8rem;

    &__filters {
        display: flex;
        column-gap: 3.2rem;

        @media screen and (max-width: @max-m-width) {
            display: block;
        }

        &__box {
            width: 100%;
            margin: 2.4rem 0;
            padding: 2.4rem;
            background-color: @white;
            box-shadow: 1px 2px 12px rgba(0, 66, 108, 0.15);
            border-radius: 4px;

            &__option {
                display: flex;
                padding: 0.4rem 0;

                &:last-child {
                    margin-bottom: 0;
                }

                @media screen and (max-width: @max-m-width) {
                    margin-bottom: 0.8rem;
                }
            }
        }
    }

    &__button {
        display: flex;
        justify-content: flex-end;
    }

    &__range-error-notification {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
}
</style>
