<template>
    <span class="tooltip">
        <pui-tooltip />
        <span v-pui-tooltip="{ message }"><slot /></span>
    </span>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class Tooltip extends Vue {
    /**
     * Message to be shown in tooltip.
     */
    @Prop()
    private message!: string;
}
</script>

<style lang="less">
.tooltip {
    .pui-tooltip {
        margin-top: -15rem;
        margin-left: -5rem;
    }
}
</style>
