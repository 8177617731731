<template>
    <div>
        <pui-form-input-field
            v-model="searchTerm"
            :trailing-icon-is-clickable="true"
            :placeholder-text="placeholderText"
            trailing-icon-name="search"
            class="table-search"
            @trailing-icon:click="filterByKeyword"
            @keyup.native.enter="filterByKeyword"
        />
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import { LocaleMessage } from 'vue-i18n';

@Component({})
export default class TableSearchComponent extends Vue {
    @Prop({ default: () => '' })
    private placeholderText!: string | LocaleMessage;

    private searchTerm = '';

    @Emit()
    filterByKeyword(): string {
        return this.searchTerm;
    }
}
</script>

<style lang="less">
.table-search {
    width: 200px !important;
}
</style>
