<template>
    <div class="comments-tab">
        <pui-loader-spinner
            v-if="isSubmitting || isLoadingUsers"
            :title="$t('commentLightbox.loader.title')"
            :message="$t('commentLightbox.loader.message')"
        />
        <div v-else>
            <div
                v-if="commentDetails.comment"
                class="comments-tab__comment"
            >
                <pui-comment
                    :author-username="modifiedByName"
                    :creation-date="commentDetails.comment.createdAt"
                    :edit-date="commentDetails.comment.modifiedAt"
                    :comment="commentDetails.comment.text"
                >
                    <template
                        v-if="showTaggedUsersList"
                        slot="additional-content"
                    >
                        <div class="comments-tab__comment__tagged-users">
                            {{ taggedUsersList }}
                        </div>
                    </template>
                </pui-comment>
                <hr
                    v-if="isAllowedToEditComments"
                    class="comments-tab__comment__separator"
                >
            </div>
            <pui-form
                v-if="isAllowedToEditComments"
                class="comments-tab__form"
                @submit.prevent="handleSubmission"
            >
                <div class="pui-form-grid-row">
                    <pui-form-group
                        :label="fields.comment.label"
                        :is-valid="fields.comment.isValid"
                        v-pui-form-grid-column="{s:12}"
                    >
                        <pui-form-textarea
                            v-model="fields.comment.value"
                            :placeholder="fields.comment.placeholder"
                            :is-valid="fields.comment.isValid"
                            :rows="4"
                            grow
                            @input="handleValidationForField('comment')"
                        />
                        <span slot="error-message">{{ errorMessage(fields.comment.value) }}</span>
                    </pui-form-group>

                    <pui-form-group
                        :label="fields.people.label"
                        :is-valid="fields.people.isValid"
                        v-pui-form-grid-column="{s:12, l:8}"
                    >
                        <uam-user-input
                            :initial-value="initialUserList"
                            :is-valid="fields.people.isValid"
                            @update:users="updateUsers"
                        />
                        <span slot="error-message">{{ $t('commentLightbox.errorMessage.errorOccurred') }}</span>
                    </pui-form-group>

                    <div class="comments-tab__form__footer pui-form-grid-column-12@s">
                        <pui-button
                            state="secondary"
                            @click="$emit('close-lightbox', false);"
                        >
                            {{ $t('commentLightbox.actions.cancel') }}
                        </pui-button>

                        <pui-button
                            class="comments-tab__form__footer__button"
                            :disabled="isSubmitButtonDisabled"
                            type="submit"
                        >
                            {{ commentDetails.comment ? $t('commentLightbox.actions.update') : $t('commentLightbox.actions.add') }}
                        </pui-button>
                    </div>
                </div>
            </pui-form>
        </div>
    </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CachedUser, CommentsTabFields, MonitorDetails, SelectOption } from '@/models/interfaces';
import UamUserInput from './uam-user-input.vue';
import { AccessControlled } from '@/mixins/access-controlled';
import { Permission } from '@/config/permissions';
import { UserInformation } from '@/store/modules/user.module';

@Component({
    components: {
        UamUserInput,
    }
})
export default class CommentsTabComponent extends mixins(AccessControlled) {
    @Prop({ default: () => null })
    private commentDetails!: MonitorDetails;

    private readonly MIN_CHARACTER_COUNT = 2;

    private abortController = new AbortController();

    private isSubmitting = false;
    private isLoadingUsers = false;

    private initialUserList: SelectOption[] = [];
    private modifiedByName = this.commentDetails.comment?.modifiedBy ?? '';

    private fields: CommentsTabFields = {
        comment: {
            label: this.$t('commentLightbox.fields.comment.label'),
            placeholder: this.$t('commentLightbox.fields.comment.placeholder'),
            value: this.commentDetails.comment?.text ?? '',
            isRequired: true,
            isValid: true,
        },
        people: {
            label: this.$t('commentLightbox.fields.people.label'),
            value: this.commentDetails.comment?.taggedUser ?? [],
            isRequired: false,
            isValid: true,
        },
    };

    private get isAllowedToEditComments(): boolean {
        return this.hasSomePermission(Permission.EDITOR, Permission.ADMIN);
    }

    private get isSubmitButtonDisabled(): boolean {
        return this.isSubmitting
        || this.errorsDetected
        || this.fields.comment.value.trim() === ''
        || this.isUsersListInvalid;
    }

    private get errorsDetected(): boolean {
        return !this.fields.comment.isValid || !this.fields.people.isValid;
    }

    private get userInformation(): UserInformation {
        return this.$store.getters['user/getUserInformation'];
    }

    private get cachedUsers(): CachedUser[] {
        return this.$store.getters['comments/getCachedUsers'];
    }

    private get showTaggedUsersList(): boolean {
        return this.commentDetails.comment?.taggedUser?.length > 0;
    }

    private get taggedUsersList(): string {
        const initialValue = '';
        return this.initialUserList.reduce(
            (previousValue, currentValue) => previousValue + ' @' + currentValue.label,
            initialValue
        );
    }

    private async created(): Promise<void> {
        if (this.commentDetails.comment?.taggedUser?.length > 0) {
            this.isLoadingUsers = true;
            this.initialUserList = await Promise.all(this.commentDetails.comment.taggedUser.map(async (userKid: string): Promise<SelectOption> => {
                const label = await this.getNameByKid(userKid);
                if (label === null) {
                    this.fields.people.isValid = false;
                }
                return {
                    label,
                    value: userKid
                }
            }));
            this.isLoadingUsers = false;
        }
        if (this.commentDetails.comment?.modifiedBy) {
            this.modifiedByName = await this.getNameByKid(this.commentDetails.comment.modifiedBy);
        }
    }

    private async getNameByKid(userKid: string): Promise<string> {
        const result = this.cachedUsers.find((user: CachedUser) => user.userKid === userKid);
        if (result) {
            return result.name;
        }
        try {
            const result = await this.$store.dispatch('comments/getUserByKid', { userKid, signal: this.abortController.signal });
            if (result) {
                return result.name;
            }
            return userKid;
        } catch (e) {
            return userKid;
        }
    }

    private errorMessage(comment: string): string | undefined {
        if (comment.trim().length === 0) {
            return this.$t('commentLightbox.errorMessage.required');
        } else if (comment.trim().length < this.MIN_CHARACTER_COUNT) {
            return this.$t('commentLightbox.errorMessage.minCharacters', { minNumberOfCharacters: this.MIN_CHARACTER_COUNT });
        }
    }

    private handleValidationForField(fieldKey: keyof CommentsTabFields): void {
        if (
            (!this.fields[fieldKey].value && this.fields[fieldKey].isRequired) ||
            (fieldKey === 'comment' && this.fields.comment.value.trim().length < this.MIN_CHARACTER_COUNT) ||
            (fieldKey === 'people' && this.isUsersListInvalid)
        ) {
            this.setFieldErrorState(fieldKey);
        } else {
            this.resetFieldErrorState(fieldKey);
        }
    }

    private get isUsersListInvalid(): boolean {
        const currentUserList = this.fields.people.value;
        return (currentUserList as string[]).some((userKid) => userKid === null || userKid === undefined);
    }

    private setFieldErrorState(fieldKey: keyof CommentsTabFields): void {
        if (this.isValidFieldKey(fieldKey)) {
            this.fields[fieldKey].isValid = false;
        }
    }

    private resetFieldErrorState(fieldKey: keyof CommentsTabFields): void {
        if (this.isValidFieldKey(fieldKey)) {
            this.fields[fieldKey].isValid = true;
        }
    }

    private isValidFieldKey(fieldKey: keyof CommentsTabFields): boolean {
        return this.fields[fieldKey] !== undefined && this.fields[fieldKey].isValid !== undefined;
    }

    private updateUsers(users: SelectOption[]): void {
        this.fields.people.value = users.map(user => user.value);
        this.handleValidationForField('people');
    }

    private async handleSubmission(): Promise<void> {
        Object.keys(this.fields).forEach((fieldKey) =>
            this.handleValidationForField(fieldKey as keyof CommentsTabFields),
        );
        if (this.errorsDetected) return;

        this.isSubmitting = true;

        const { name, kid } = this.userInformation;

        const { detailId, data: { message }, status, resultRyg, resultRygOverride } = this.commentDetails;

        const params = {
            message,
            detailId,
            username: name,
            modifiedBy: kid,
            overrideComment: this.fields.comment.value.trim(),
            taggedUser: this.fields.people.value,
            resultRyg: resultRyg ?? status.toString(),
            resultRygOverride,
        };

        try {
            await this.$store.dispatch('comments/postComment', { params, signal: this.abortController.signal });
            const action = this.commentDetails.comment ? this.$t('commentLightbox.apiSuccess.action.updated') : this.$t('commentLightbox.apiSuccess.action.added');
            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('commentLightbox.apiSuccess.title'),
                copy: this.$t('commentLightbox.apiSuccess.description', { action })
            });
            this.$emit('close-lightbox', true);
        } catch (e) {
            const serverErrorMessage = `: ${(e as any)?.response?.data?.errors?.[0] ?? 'N/A'}`;
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('commentLightbox.apiError.title'),
                copy: this.$t('commentLightbox.apiError.description') + serverErrorMessage
            });
            this.isSubmitting = false;
        }
    }
}
</script>

<style lang="less">
@import '~@/variables.less';

.comments-tab {
    &__comment {
        &__tagged-users {
            color: @uniper-blue;
        }
        &__separator {
            height: 1px;
            background-color: @warm-grey;
            margin: 0 2.4rem;
            border: none;
        }
    }
    &__form {
        &__footer {
            margin: 3.2rem 0;
            display: flex;
            justify-content: flex-end;

            &__button {
                margin-left: 1.2rem;
            }
        }
        &__input {
            margin-top: 2.4rem;
        }
    }
}

</style>
