<template>
    <pui-loader :promise="emailSettingsPromise">
        <edit-email-template-modal
            :ref="REF_CONSTANTS.EDIT_EMAIL_TEMPLATE_MODAL"
            @update:email-template="fetchEmailSettings"
        />
        <div class="email-settings-tab">
            <pui-collapsible
                v-for="(status, statusKey) in STATUS_SETTINGS_CONFIG"
                :key="statusKey"
                :expandable="false"
                :headline="$t(status.label)"
                class="email-settings-tab__collapsible"
            >
                <div
                    v-for="(process, processKey, processIndex) in PROCESSES"
                    :key="processIndex"
                    class="email-settings-tab__collapsible__content"
                >
                    <hr
                        v-if="processIndex !== 0"
                        class="email-settings-tab__collapsible__content__process-collapsible__separator"
                    >
                    <pui-collapsible
                        :open="Number(statusKey) === openedEmailSection.color && Number(processKey) === openedEmailSection.process"
                        :headline="$t(process.label)"
                        class="email-settings-tab__collapsible__content__process-collapsible"
                    >
                        <div class="email-settings-tab__collapsible__content__process-content">
                            <div
                                v-for="(item, emailIndex) in getEmailSettings(statusKey, processKey)"
                                :key="emailIndex"
                                class="email-settings-tab__collapsible__content__process-content__setting-row"
                            >
                                <pui-headline type="h6">
                                    {{ $t(SUB_PROCESSES[item.subProcess].label) }}
                                </pui-headline>
                                <pui-form-input-field
                                    :value="checkEmptyForEmail(item.email)"
                                    :is-disabled="true"
                                    class="email-settings-tab__collapsible__content__process-content__setting-row__email-field"
                                />
                                <pui-button
                                    small
                                    icon="edit"
                                    @click="openEditEmailTemplateModal(item)"
                                >
                                    {{ $t('emailSettingsTab.editTemplate') }}
                                </pui-button>
                            </div>
                        </div>
                    </pui-collapsible>
                </div>
            </pui-collapsible>
            <pui-collapsible
                :expandable="false"
                :configurable="true"
                :headline="$t('emailSettingsTab.commentsTemplate')"
                class="email-settings-tab__collapsible"
            >
                <template #collapsibleConfigurationContent>
                    <pui-button
                        small
                        icon="edit"
                        @click="openEditEmailTemplateModal()"
                    >
                        {{ $t('emailSettingsTab.editTemplate') }}
                    </pui-button>
                </template>
            </pui-collapsible>
        </div>
    </pui-loader>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { EmailSettingsItem } from '@/models/interfaces';
import { AMBER_STATUS, RED_STATUS } from '@/config/process-statuses';
import { PROCESSES, SUB_PROCESSES } from '@/config/processes';
import EditEmailTemplateModal
    from '@/components/settings/email-settings-tab/edit-email-template-modal/edit-email-template-modal.vue';
import { EmailTemplateSectionIdentifier } from '@/store/modules/settings.module';

const REF_CONSTANTS = {
    EDIT_EMAIL_TEMPLATE_MODAL: 'editEmailTemplateModal',
} as const;

@Component({
    components: {
        EditEmailTemplateModal,
    },
})
export default class EmailSettingsTabComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly PROCESSES = PROCESSES;
    private readonly SUB_PROCESSES = SUB_PROCESSES;
    private readonly STATUS_SETTINGS_CONFIG = {
        [AMBER_STATUS]: {
            label: 'emailSettingsTab.yellowTemplates',
        },
        [RED_STATUS]: {
            label: 'emailSettingsTab.redTemplates',
        },
    } as const;

    $refs!: {
        [REF_CONSTANTS.EDIT_EMAIL_TEMPLATE_MODAL]: EditEmailTemplateModal;
    };

    private abortController = new AbortController();
    private emailSettingsPromise: Promise<void> | null = null;

    private get emailSettings(): EmailSettingsItem[] {
        return this.$store.getters['settings/getEmailSettings'];
    }

    private get openedEmailSection(): EmailTemplateSectionIdentifier {
        return this.$store.getters['settings/getOpenedEmailTemplateSection'];
    }

    created(): void {
        this.fetchEmailSettings();
    }

    beforeDestroy(): void {
        this.abortController.abort();
    }

    private fetchEmailSettings(): void {
        this.abortController.abort();
        this.abortController = new AbortController();

        this.emailSettingsPromise = this.$store.dispatch('settings/fetchEmailSettings', this.abortController.signal)
            .then(() => this.$store.dispatch('settings/fetchEmailTags', this.abortController.signal))
            .catch(() => {
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('apiError.errorGettingData'),
                    copy: this.$t('apiError.couldNotGetEmailSettings')
                });
            });
    }

    private getEmailSettings(status: string | number, process: string | number): EmailSettingsItem[] {
        return this.emailSettings.filter(e => e.color === Number(status) && e.process === Number(process));
    }

    private checkEmptyForEmail(value: string): string {
        return !value ? this.$t('emailSettingsTab.notConfigured') : value;
    }

    private openEditEmailTemplateModal(emailSettingsItem?: EmailSettingsItem): void {
        this.$refs[REF_CONSTANTS.EDIT_EMAIL_TEMPLATE_MODAL].openEmailTemplate(emailSettingsItem);
    }
}
</script>

<style lang="less">
@import '~@/variables.less';

.email-settings-tab {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__collapsible {
        margin-top: 0.8rem;
        background-color: @white;

        &__content {
            display: flex;
            flex-direction: column;

            &__process-collapsible {
                box-shadow: none !important;

                &__separator {
                    height: 0.8px;
                    background-color: @grey-lighter;
                    margin: 0 1.6rem;
                    border: none;
                }

                .pui-collapsible__header {
                    padding: 0 1.6rem !important;
                    background-color: @white !important;
                }
            }

            &__process-content {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                padding: 2rem 2rem 2rem 4.8rem;

                &__setting-row {
                    display: grid;
                    align-items: center;

                    grid-template-columns: minmax(20%, 1fr) 1fr 1fr;

                    gap: 1.5rem;

                    &__email-field {
                        width: 50rem;
                    }
                }
            }
        }
    }
}
</style>
