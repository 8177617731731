import BaseService from '@/utils/base-service';
import { ApiResponse, ApiResponseList } from '@/models';
import { UamUser, UamUserSearchItem } from '@/models/services/uam';
import { sdk } from '@/utils/coode-sdk-config';
import { Api } from '@coode/fe-sdk';

/**
 * @name UamService
 * @description This class is used to communicate with the Core UAM service for
 * information related to user identities.
 */
export class UamService extends BaseService {
    constructor() {
        super('users');
    }

    protected get api(): Api {
        return  sdk?.core.userAccessManagement.request.api
    }

    public async getUsers(query: string, signal?: AbortSignal): Promise<ApiResponse<ApiResponseList<UamUserSearchItem>>> {
        const urlParams = new URLSearchParams();
        urlParams.append('Term', query);

        return (await this.api.get<ApiResponse<ApiResponseList<UamUserSearchItem>>>(`${this.resourcePath}?${urlParams.toString()}`, { signal })).data;
    }

    public async getUserByKid(kid: string, signal?: AbortSignal): Promise<ApiResponse<UamUser>> {
        return (await this.api.get<ApiResponse<UamUser>>(`${this.resourcePath}/by-kid/${kid}`, { signal })).data;
    }

}
