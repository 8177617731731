<template>
    <pui-loader-error
        :title="$t('forbidden.title')"
        :message="$t('forbidden.message')"
        icon="error-alert"
    />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class ForbiddenPageComponent extends Vue {}
</script>
