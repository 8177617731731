<template>
    <pui-lightbox
        :ref="COMMENT_LIGHTBOX_REF"
        :default-header-label="$t('commentLightbox.title')"
        :close-on-e-s-c="false"
        @puilightbox:close="emitClose()"
    >
        <pui-grid-container>
            <comments-tab
                :comment-details="commentDetails"
                @close-lightbox="(refresh) => emitClose(refresh)"
            />
        </pui-grid-container>
    </pui-lightbox>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { MonitorDetails, VueWithRefs } from '@/models/interfaces';
import CommentsTab from './comments-tab.vue';

@Component({
    components: {
        CommentsTab,
    }
})
export default class CommentsLightboxComponent extends Vue {
    private readonly COMMENT_LIGHTBOX_REF = 'comment-lightbox';

    @Prop()
    private lightboxOpen!: boolean;

    @Prop()
    private commentDetails!: MonitorDetails;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private open(): void {
        const ref = this.$refs[this.COMMENT_LIGHTBOX_REF];
        ref && (ref as VueWithRefs).open();
    }

    private close(): void {
        const ref = this.$refs[this.COMMENT_LIGHTBOX_REF];
        ref && (ref as VueWithRefs).close();
    }

    private emitClose(refresh = false): void {
        this.$emit('close-lightbox', refresh);
    }
}
</script>
