<template>
    <div class="settings-page">
        <pui-tabs
            :selected="selectedTabIndex"
            class="settings-page__tabs"
            @changed="onTabChanged"
        >
            <pui-tab
                v-for="tab in tabs"
                :key="tab"
                :title="tab"
                class="settings-page__tab"
            >
                <thresholds-tab v-if="selectedTabIndex === 0" />
                <email-settings-tab v-else-if="selectedTabIndex === 1" />
            </pui-tab>
        </pui-tabs>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import ThresholdsTab from '@/components/settings/thresholds-tab/thresholds-tab.vue';
import EmailSettingsTab from '@/components/settings/email-settings-tab/email-settings-tab.vue';

@Component({
    components: {
        ThresholdsTab,
        EmailSettingsTab,
    },
})
export default class SettingsComponent extends Vue {
    private selectedTabIndex = 0;
    private tabs = [this.$t('threshold'), this.$t('emailSettings')];

    private onTabChanged(tabIndex: number): void {
        this.selectedTabIndex = tabIndex;
    }
}
</script>

<style lang="less">
.settings-page {
    padding: 3.2rem;

    &__tab {
        margin-top: 1.5rem;
    }
}
</style>
