<template>
    <div class="completeness-check-modal-details">
        <table-attachment
            attachment-position="top"
            class="completeness-check-modal-details__attachment"
        >
            <table-search
                :placeholder-text="$t('completenessCheckDetailModal.filterById')"
                @filter-by-keyword="filterByKeywordChanged"
            />
        </table-attachment>
        <pui-loader :promise="completenessCheckDetailsPromise">
            <table class="completeness-check-modal-details__table">
                <thead>
                    <tr>
                        <th
                            v-for="(column, index) in columns"
                            :key="index"
                            :class="[index === 0 ? 'completeness-check-modal-details__table__value-identification-cell--heading' : 'completeness-check-modal-details__table__hour-cell--heading']"
                        >
                            {{ headings[column] }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(data, dataIndex) in tableData"
                        :key="dataIndex"
                    >
                        <td class="completeness-check-modal-details__table__value-identification-cell--data">
                            {{ data[columns[0]] }}
                        </td>
                        <td
                            v-for="(hour, hourIndex) in hoursInDayStrings"
                            :key="hourIndex"
                            class="completeness-check-modal-details__table__hour-cell--data"
                        >
                            <completeness-check-modal-details-table-cell
                                :positioning="hourIndex < hoursInDayStrings.length / 2 ? 'right' : 'left'"
                                :hourly-data="data[hour]"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </pui-loader>
        <table-attachment
            attachment-position="bottom"
            class="completeness-check-modal-details__attachment"
        >
            <table-pagination
                :total-count="completenessCheckFilteredItemsCount"
                @change:page-number="pageNumberChanged"
                @change:page-size="pageSizeChanged"
            />
        </table-attachment>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import {
    CompletenessCheckDetailItem,
    CompletenessCheckDetailItemHourly,
    GetCompletenessCheckDetailItemPayload
} from '@/models/interfaces';
import CompletenessCheckModalDetailsTableCell
    from '@/components/completeness-check/completeness-check-details-modal/completeness-check-modal-details-tab/completeness-check-modal-details-table-cell/completeness-check-modal-details-table-cell.vue';
import TableAttachment from '@/components/ui/table/table-attachment.vue';
import TableSearch from '@/components/ui/table/table-search.vue';
import TablePagination, { TablePaginationDefaults } from '@/components/ui/table/table-pagination.vue';
import { Prop } from 'vue-property-decorator';

type TableDataRow = {
    [key: string]: CompletenessCheckDetailItemHourly | string;
};

@Component({
    components: {
        TablePagination,
        TableSearch,
        TableAttachment,
        CompletenessCheckModalDetailsTableCell,
    },
})
export default class CompletenessCheckModalDetailsTabComponent extends Vue {
    private readonly HOURS_IN_DAY = 24;

    /*
     * This prop is used to know the payload for this specific Completeness Check details query.
     */
    @Prop({ required: true })
    private completenessDetailPayload!: GetCompletenessCheckDetailItemPayload;

    private abortController = new AbortController();
    private completenessCheckDetailsPromise: Promise<void> | null = Promise.resolve();

    private filterByKeyword: string | null = null;
    private pageNumber = TablePaginationDefaults.INITIAL_PAGE_NUMBER;
    private pageSize = TablePaginationDefaults.INITIAL_PAGE_SIZE;

    private get completenessCheckDetailItems(): CompletenessCheckDetailItem[] {
        return this.$store.getters['completeness/getFilteredItems'];
    }

    private get completenessCheckFilteredItemsCount(): number {
        return this.$store.getters['completeness/getFilteredItemsCount'];
    }

    private get tableData(): TableDataRow[] {
        const data: TableDataRow[] = [];

        this.completenessCheckDetailItems.forEach(item => {
            const tableItem = {
                valueIdentification: item.valueIdentification,
            };

            item.hourlies.forEach(hourItem => {
                tableItem[hourItem.hour.toString()] = hourItem;
            });

            data.push(tableItem);
        });

        return data;
    }

    private get hoursInDayStrings(): string[] {
        return [...Array(this.HOURS_IN_DAY).keys()].map(e => e.toString());
    }

    private get columns(): string[] {
        return ['valueIdentification', ...this.hoursInDayStrings];
    }

    private get headings(): Record<string, string> {
        const headings = {
            valueIdentification: this.$t('valueIdentification'),
        };

        this.hoursInDayStrings.forEach(e => {
            headings[e] = e;
        });

        return headings;
    }

    private filterByKeywordChanged(keyword: string): void {
        this.pageNumber = 1;
        this.filterByKeyword = keyword;
        this.tableFiltersChanged();
    }

    private pageSizeChanged(pageSize: number): void {
        if (this.pageSize === pageSize) {
            return;
        }

        if (TablePaginationDefaults.PAGE_SIZE_SELECTION_OPTIONS.includes(pageSize)) {
            this.pageNumber = 1;
            this.pageSize = pageSize;
            this.tableFiltersChanged();
        }
    }

    private pageNumberChanged(pageNumber: number): void {
        if (this.pageNumber === pageNumber) {
            return;
        }

        this.pageNumber = pageNumber;
        this.tableFiltersChanged();
    }

    private tableFiltersChanged(): void {
        this.abortController.abort();
        this.abortController = new AbortController();

        this.completenessCheckDetailsPromise = this.$store.dispatch('completeness/fetchFilteredDetailItems', {
            signal: this.abortController.signal,
            valueIdSearchText: this.filterByKeyword,
            itemsPerPage: this.pageSize,
            pageNo: this.pageNumber,
            ...this.completenessDetailPayload,
        }).catch(() => {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('apiError.errorGettingData'),
                copy: this.$t('apiError.couldNotGetDetailItems')
            });
        });
    }
}
</script>

<style lang="less">
@import '~@/variables.less';

.completeness-check-modal-details {
    margin-top: 3rem;

    &__attachment {
        background-color: @pale-grey;
    }

    &__table {
        width: 100%;
        background-color: @white;
        border-collapse: collapse;

        &__value-identification-cell {
            &--heading {
                height: 2rem;
                width: 1%;
                padding-left: 1rem;
                padding-right: 1rem;
                text-align: left;
                border: @lighter-grey 0 solid;
                border-left-width: 1px;
                border-bottom-width: 1px;
            }

            &--data {
                width: 1%;
                padding-left: 1rem;
                padding-right: 1rem;
                white-space: nowrap;
                text-align: left;
                border: @lighter-grey 0 solid;
                border-left-width: 1px;
            }
        }

        &__hour-cell {
            &--heading {
                height: 2rem;
                min-width: 1rem !important;
                border: @lighter-grey 0 solid;
                border-right-width: 1px;
                border-left-width: 1px;
            }

            &--data {
                min-width: 1rem !important;
                border: @lighter-grey 1px solid;
            }
        }
    }
}
</style>
