<template>
    <div :class="['table-attachment', `table-attachment--${attachmentPosition}-attached`]">
        <slot />
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class TableAttachmentComponent extends Vue {
    @Prop()
    attachmentPosition!: 'top' | 'bottom';
}
</script>

<style lang="less">
.table-attachment {
    background: white;
    padding: 0.8rem 1rem;

    &--top-attached {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    &--bottom-attached {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        box-shadow: 0 1.2px 2px rgba(0, 0, 0, 0.2);
    }
}

</style>
